import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useCart } from 'react-use-cart';
import queryString from 'query-string';
import { navigate } from '@reach/router';
import { useFirebase } from '../components/Firebase';
// import PotockLink from '../components/PotockLink';

import Layout from '../components/Layout';

// import ReviewsList from '../components/ReviewsList';
import SEO from '../components/SEO';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import BackToShopLink from '../components/BackToShopLink';

function CommonProductPage({ data: { wcProducts: product }, location }) {
  // * Must be before setting state
  const variantId = parseInt(queryString.parse(location.search).variantId);

  const { product_variations: variants } = product;
  variants.sort((a, b) => a.menu_order - b.menu_order);

  let firstVariantWithStock = variants.find(v => v.stock_quantity > 0);

  let [firstVariant] = firstVariantWithStock
    ? [firstVariantWithStock]
    : variants.length > 0
    ? variants
    : [null];

  // Init states
  const [variantQuantity, setVariantQuantity] = useState(1);
  const [activeVariantId, setActiveVariantId] = useState(
    variantId || (firstVariant && firstVariant.id)
  );
  const [variantStockQuantity, setVariantStockQuantity] = useState(-1);

  // Get firebase
  const { firebase } = useFirebase();

  // Run async effects
  useEffect(() => {
    if (activeVariantId !== undefined) {
      navigate(`?variantId=${activeVariantId}`, { replace: true });
    }
  }, [activeVariantId]);

  useEffect(() => {
    if (firebase) {
      setVariantStockQuantity(-1);

      firebase.getStock({ id: product.wordpress_id }).then(response => {
        if (response.downloadable) {
          setVariantStockQuantity(1);
        } else {
          if (response.variations.length) {
            if (activeVariantId) {
              let variantStock = response.variations.find(
                v => parseInt(v.id) === parseInt(activeVariantId)
              );

              const quantity = variantStock ? variantStock.stock_quantity : 0;
              setVariantStockQuantity(quantity > 0 ? quantity : 0);
            }
          } else {
            setVariantStockQuantity(
              response.stock_quantity > 0 ? response.stock_quantity : 0
            );
          }
        }
      });
    }
  }, [firebase, activeVariantId, product]);

  // Some run the rest of the code
  const cart = useCart();
  const { addItem } = cart;

  const activeVariant = variants.find(
    variant => variant.id === parseInt(activeVariantId)
  );

  if (!product) return null;

  const [firstImage] = product.images.length ? product.images : [null];

  const imageSrc = firstImage
    ? firstImage.localFile.childImageSharp.fluid.src
    : null;
  const imageFluid = firstImage
    ? firstImage.localFile.childImageSharp.fluid
    : null;

  const maxQuantity = product.downloadable
    ? 1
    : Math.min(5, variantStockQuantity);

  // Render
  return (
    <Layout fullPage={false} shop={true}>
      <SEO pageTitle={product.name} image={imageSrc} />

      <BackToShopLink />

      <div className="lg:flex px-6 pt-10">
        <div
          className="mb-8 px-6 md:mb-0 lg:w-3/5"
          style={{ maxHeight: 'calc(100vh - 100px)' }}
        >
          <div
            className="w-full relative bg-gainsboro rounded-lg"
            style={{ maxHeight: 'calc(100vh - 100px)' }}
          >
            <Img
              className="h-full"
              fluid={imageFluid}
              alt={product.name}
              title={product.name}
              imgStyle={{ objectFit: 'contain' }}
              style={{
                maxHeight: 'calc(100vh - 100px)',
                backgroundColor: 'black',
              }}
            />
          </div>
        </div>

        <div className="px-6 md:py-3 lg:w-2/5">
          <h1 className="font-bold text-3xl md:text-4xl mb-3 text-white leading-tight">
            {product.name}
          </h1>

          <div className="mb-6">
            <p className="font-semibold text-2xl text-white">
              {product.price} zł
            </p>
          </div>

          {product.description && (
            <div className="mb-6">
              <p
                className="leading-loose text-lightgray"
                dangerouslySetInnerHTML={{ __html: product.description }}
              />
            </div>
          )}
          <div className="md:flex md:flex-wrap -mx-3">
            {product.attributes && (
              <React.Fragment>
                <div className="px-3 mb-6">
                  {product.attributes.map(attribute => (
                    <React.Fragment key={attribute.name}>
                      <label
                        className="block text-sm font-bold tracking-widest uppercase mb-2 text-white"
                        htmlFor="style"
                      >
                        {attribute.name}
                      </label>

                      <div className="relative">
                        <select
                          id="style"
                          value={activeVariant && activeVariant.id}
                          onChange={({ target: { value } }) =>
                            setActiveVariantId(value)
                          }
                          className="block appearance-none bg-gainsboro border-2 border-gainsboro focus:border-slategray px-4 py-3 pr-8 focus:outline-none focus:bg-white text-slategray focus:text-slategray rounded-lg"
                        >
                          {variants.map((variant, index) => (
                            <option key={index} value={variant.id}>
                              {variant.attributes[0].option}
                            </option>
                          ))}
                        </select>

                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-white">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>

                <div className="px-3 mb-6">
                  <label
                    className="block text-sm font-bold tracking-widest uppercase mb-2 text-white"
                    htmlFor="quantity"
                  >
                    Ilość
                  </label>

                  <div className="relative">
                    {maxQuantity > 0 ? (
                      <>
                        <select
                          id="quantity"
                          value={variantQuantity}
                          className="block appearance-none w-full bg-gainsboro border-2 border-gainsboro focus:border-slategray px-4 py-3 pr-8 focus:outline-none focus:bg-white text-slategray focus:text-slategray rounded-lg"
                          onChange={({ target: { value } }) =>
                            setVariantQuantity(parseInt(value))
                          }
                        >
                          {new Array(
                            Math.min(
                              activeVariant
                                ? activeVariant.stock_quantity > 0
                                  ? activeVariant.stock_quantity
                                  : 0
                                : maxQuantity,
                              maxQuantity
                            )
                          )
                            .fill(0)
                            .map((v, k) => k + 1)
                            .map(i => ({ value: i, label: i }))
                            .map(({ value, label }) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ))}
                        </select>

                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-slategray">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </>
                    ) : maxQuantity === 0 ? (
                      <span className="text-red">Brak na stanie</span>
                    ) : (
                      <span className="text-white">
                        Sprawdzamy stan magazynu...
                      </span>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="mb-6">
            <button
              className={
                'block ' +
                (maxQuantity > 0
                  ? 'bg-success hover:bg-successhover text-white '
                  : ' hidden ') +
                ' px-4 py-3 rounded-lg text-sm font-bold tracking-widest uppercase focus:outline-none'
              }
              onClick={() => {
                const name = activeVariant
                  ? product.name +
                    (' ' +
                      activeVariant.attributes[0].name
                        .toString()
                        .toLowerCase() +
                      ' ' +
                      activeVariant.attributes[0].option)
                  : product.name;

                addItem(
                  {
                    id: activeVariant ? activeVariantId : product.wordpress_id,
                    wordpress_id: product.wordpress_id,
                    price: product.price,
                    image: firstImage ? firstImage.localFile : null,
                    name,
                    description: product.description,
                    size: activeVariant
                      ? activeVariant.attributes[0].option
                      : null,
                    categories: product.categories,
                    category: product.categories[0],
                    slug: product.slug,
                    downloadable: product.downloadable,
                  },
                  variantQuantity
                );
              }}
              disabled={maxQuantity <= 0}
            >
              Dodaj do koszyka
            </button>
          </div>
        </div>
      </div>

      {/* <ReviewsList productId={product.id} reviews={product.reviews} /> */}
    </Layout>
  );
}

export const pageQuery = graphql`
  query CommonProductQuery($id: Int!) {
    wcProducts(wordpress_id: { eq: $id }) {
      wordpress_id
      name
      slug
      description
      downloadable
      variations
      images {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      price
      attributes {
        name
        options
        variation
      }
      product_variations {
        id
        price
        description
        attributes {
          name
          option
        }
        stock_quantity
        stock_status
        menu_order
      }
      categories {
        name
        slug
      }
    }
  }
`;

export default CommonProductPage;
